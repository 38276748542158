import React, { useContext, useState, useEffect, useRef } from "react";
import { BrowserRouter, Link, Route, Routes, useNavigate } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2'

function Investmentplans() {
    const [investment, setinvestment] = useState(0)
    const [coinToUse, setcoinToUse] = useState("")
    const [amountToInvest, setamountToInvest] = useState("0")
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)
        console.log(investment)
    }, [investment])


    const [{ userdetails, loggedin, tradingpair, selectedinvestment }, dispatch] = useContext(GlobalContext);

    const setinvestments = (data) => {
        dispatch({ type: "setselectedinvestment", snippet: data });
    };


    const setcointoinvestwith = (data) => {
        dispatch({ type: "setcointoinvestwith", snippet: data });
    };


    const proceedToInvestment = () => {
        if (coinToUse != "" && investment != 0) {

            setcointoinvestwith(coinToUse)

            if (investment == 1) {
                const data = {
                    name: "Rookie",
                    min: "100",
                    max: "4999",
                    duration: "1",  //no of dats
                    profit: "10",    // daily profit
                    paymentmethod: coinToUse,
                    description: 'Earn 10% daily'
                }

                setinvestments(data)
            } else if (investment == 6) {
                const data = {
                    name: "Rookie 2",
                    min: "500",
                    max: "4999",
                    duration: "2",  //no of dats
                    profit: "10",    // daily profit
                    paymentmethod: coinToUse,
                    description: 'Earn 10% daily'
                }

                setinvestments(data)
            } else if (investment == 2) {
                const data = {
                    name: "Professional",
                    min: "2000",
                    max: "9999",
                    duration: "3",
                    profit: "13.3",
                    paymentmethod: coinToUse,
                    description: 'Earn 13.3% daily'
                }

                setinvestments(data)
            } else if (investment == 3) {
                const data = {
                    name: "Megagoldtrades",
                    min: "5000",
                    max: "19999",
                    duration: "4",
                    profit: "15",
                    paymentmethod: coinToUse,
                    description: 'Earn 15% daily'
                }

                setinvestments(data)
            } else if (investment == 4) {
                const data = {
                    name: "Stocks",
                    min: "10000",
                    max: "50000",
                    duration: "5",
                    profit: "12",
                    paymentmethod: coinToUse,
                    description: 'Earn 12% daily'
                }

                setinvestments(data)
            } else if (investment == 5) {
                const data = {
                    name: "Commodities",
                    min: "5000",
                    max: "100000",
                    duration: "6",
                    profit: "10",
                    paymentmethod: coinToUse,
                    description: 'Earn 10% daily'
                }

                setinvestments(data)
            }
            console.log(investment)
            console.log("yhgbuigbyewrvbyivydwf")
            navigate("/investments")

        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "select a coin for payments and an Investment paln",
            })

        }



    }

    const setnav = () => {
        // const a = document.querySelector(".toggle-nav")
        const b = document.querySelector(".nk-header-menu")
        // a.classList.toggle("active")
        b.classList.toggle("mobile-menu")
        b.classList.toggle("nk-header-active")
        // b.classList.toggle("navbar-mobile")
        console.log("jnjnjininjnjnj")
    }

    const toggletrig = () => {
        const a = document.querySelector(".toggle-class")
        //const b = document.querySelector(".navbar")
        a.classList.toggle("active")


    }

    const showtopnav = () => {
        const b = document.querySelector(".dropdown-menu-s1")
        b.classList.toggle("showtopnav")
        b.classList.toggle("show")
        // b.classList.toggle("navbar-mobile")
        console.log("jnjnjininjnjnj")
    }



    return (
        <div>
            {/* <meta httpEquiv="content-type" content="text/html;charset=UTF-8" />
            <meta charSet="utf-8" />
            <meta name="author" content="Megagoldtrades Investment Management Company" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta name="description" content="A powerful and conceptual apps base dashboard template that especially build for developers and programmers." />
            <link rel="shortcut icon" href="../images/favicon.png" />
            <title>Investment Dashboard - Megagoldtrades Investment Management Company</title>
            <link rel="stylesheet" href="../assets/css/dashlite8162.css?ver=1.9.0" />
            <link id="skin-default" rel="stylesheet" href="../assets/css/theme8162.css?ver=1.9.0" /> */}
            <div className="nk-app-root">
                <div className="nk-wrap ">
                    <div className="nk-header nk-header-fluid nk-header-fixed is-theme  nk-header-fixed">
                        <div className="container-xl wide-lg">
                            <div className="nk-header-wrap">
                                <div className="nk-menu-trigger mr-sm-2 d-lg-none" onClick={setnav}><a href="#" className="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em className="icon ni ni-menu" /></a></div>
                                <div className="nk-header-brand"><a href="./index.php" className="logo-link"><img className="logo-light logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="" /><img className="o logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="o" /><span className="nio-version" /></a></div>
                                <div className="nk-header-menu" data-content="headerNav">
                                    <div className="nk-header-mobile">
                                        <div className="nk-header-brand"><a href="./index.php" className="logo-link"><img className="logo-light logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="" /><img className="o logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="o" /><span className="nio-version" /></a></div>
                                        <div className="nk-menu-trigger mr-n2"><a onClick={setnav} href="#" className="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em className="icon ni ni-arrow-left" /></a></div>
                                    </div>
                                    <ul className="nk-menu nk-menu-main">
                                        <li className="nk-menu-item"><Link to="/dashboard" className="nk-menu-link"><span className="nk-menu-text">Dashboard</span></Link></li>

                                        <li className="nk-menu-item"><Link to="/investmentplans" className="nk-menu-link"><span className="nk-menu-text">Investments</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/investments" className="nk-menu-link"><span className="nk-menu-text">Investments Form</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/invest" className="nk-menu-link"><span className="nk-menu-text">Invest</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/withdrawalhistory" className="nk-menu-link"><span className="nk-menu-text">Payouts</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/withdrawal" className="nk-menu-link"><span className="nk-menu-text">Payouts Form</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/profile" className="nk-menu-link"><span className="nk-menu-text">Profile</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/referral" className="nk-menu-link"><span className="nk-menu-text">My Referrals</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/settings" className="nk-menu-link"><span className="nk-menu-text">Settings</span></Link></li>
                                        {/* <li className="nk-menu-item active has-sub">
                                            <a href="#" className="nk-menu-link nk-menu-toggle"><span className="nk-menu-text">Pages</span></a>
                                            <ul className="nk-menu-sub">
                                                <li className="nk-menu-item"><Link to="/investments" className="nk-menu-link"><span className="nk-menu-text">Investments Form</span></Link></li>
                                                <li className="nk-menu-item"><Link to="/withdrawal" className="nk-menu-link"><span className="nk-menu-text">Payouts Form</span></Link></li>
                                                <li className="nk-menu-item"><Link to="/referral" className="nk-menu-link"><span className="nk-menu-text">My Referrals</span></Link></li>
                                                <div id="google_translate_element" />
                                            </ul>
                                        </li> */}
                                    </ul>
                                </div>
                                <div className="nk-header-tools">
                                    <ul className="nk-quick-nav">
                                        <li className="dropdown notification-dropdown">
                                            <a href="#" className="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">
                                                <div className="icon-status icon-status-info"><em className="icon ni ni-bell" /></div>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-xl dropdown-menu-right 2">
                                                <div className="dropdown-head"><span className="sub-title nk-dropdown-title">Notifications</span><a href="#">Mark All as Read</a></div>
                                                <div className="dropdown-body">
                                                    <div className="nk-notification">
                                                        <table className="table table-ulogs">
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th className="tb-col-time">
                                                                        <span className="overline-title">Activity</span>
                                                                    </th>
                                                                    <th className="tb-col-ip">
                                                                        <span className="overline-title">Details</span>
                                                                    </th>
                                                                </tr>
                                                            </thead><thead>
                                                                <tr>
                                                                    <td className="tb-col-os">Authentication</td>
                                                                    <td className="tb-col-ip">
                                                                        <span className="sub-text">You logged in on Friday 15th of April 2022 10:12:41 AM</span>
                                                                    </td>
                                                                </tr><tr>
                                                                </tr></thead></table>
                                                    </div>
                                                    <div className="dropdown-foot center"><a href="#">View All</a></div>
                                                </div>
                                            </div></li>
                                        <li className="hide-mb-sm"><a href="#"><img href="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png" /></a></li>
                                        <li className="dropdown user-dropdown order-sm-first">
                                            <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                                <div className="user-toggle">
                                                    <div className="user-avatar sm" onClick={showtopnav}><em className="icon ni ni-user-alt" /></div>
                                                    <div className="user-info d-none d-xl-block">
                                                        <div className="user-status user-status-verified">verified</div>
                                                        {/* <div className="user-name dropdown-indicator">Osita Stephen</div> */}
                                                    </div>
                                                </div>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 is-light">
                                                <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                                    <div className="user-card">
                                                        {/* <div className="user-avatar"><span>OS</span></div> */}
                                                        {/* <div className="user-info"><span className="lead-text">Ositasteve</span><span className="sub-text">ositaositas@yahoo.com</span></div> */}
                                                        <div className="user-action"><a className="btn btn-icon mr-n2" href="profile-setting.php"><em className="icon ni ni-setting" /></a></div>
                                                    </div>
                                                </div>
                                                <div className="dropdown-inner user-account-info">
                                                    <h6 className="overline-title-alt">Account Balance</h6>
                                                    <div className="user-balance">0 <small className="currency currency-usd">USD</small></div>
                                                    <Link to="/withdrawal"> <span>Withdraw Balance</span> <em className="icon ni ni-wallet-out" /></Link>
                                                </div>
                                                {/* <div className="dropdown-inner">
                                                    <ul className="link-list">
                                                        <li><Link to="/profile"> <em className="icon ni ni-user-alt" /><span>View Profile</span></Link></li>
                                                        <li><Link to="/settings"> <em className="icon ni ni-setting-alt" /><span>Account Setting</span></Link></li>
                                                        <li><Link to="/login"> <em className="icon ni ni-activity-alt" /><span>Login Activity</span></Link></li>
                                                    </ul>
                                                </div> */}
                                                <div className="dropdown-inner">
                                                    <ul className="link-list">
                                                        <li><Link to="/"> <em className="icon ni ni-signout" /><span>Sign out</span></Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nk-content nk-content-lg nk-content-fluid">
                        <div className="container-xl wide-lg">
                            <div className="nk-content-inner">
                                <div className="nk-content-body">
                                    <div className="nk-block-head text-center">
                                        <div className="nk-block-head-content">
                                            <div className="nk-block-head-sub"><span>Choose an Option</span></div>
                                            <div className="nk-block-head-content">
                                                <h2 className="nk-block-title fw-normal">Investment Plan</h2>
                                                <div className="nk-block-des">
                                                    <p>Choose your investment plan and and select BTC or Usdt to start earning.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="nk-block">
                                        <form className="plan-iv">
                                            <div className="plan-iv-currency text-center">
                                                <ul className="nav nav-switch nav-tabs bg-white">
                                                    <li className="nav-item"><a href="#" className="nav-link" onClick={() => { setcoinToUse("Btc") }}>BTC</a></li>
                                                    <li className="nav-item"><a href="#" className="nav-link" onClick={() => { setcoinToUse("Usdt") }}>USDT Trc20</a></li>
                                                </ul>
                                            </div>
                                            <div className="plan-iv-list nk-slider nk-slider-s2">
                                                <ul className="plan-list slider-init" data-slick="{&quot;slidesToShow&quot;: 3, &quot;slidesToScroll&quot;: 1, &quot;infinite&quot;:false, &quot;responsive&quot;:[                {&quot;breakpoint&quot;: 992,&quot;settings&quot;:{&quot;slidesToShow&quot;: 2}},                  {&quot;breakpoint&quot;: 768,&quot;settings&quot;:{&quot;slidesToShow&quot;: 1}}             ]}">
                                                    <li className="plan-item" id="plan_1">
                                                        <input type="radio" id="plan-iv-1" name="plan-iv" className="plan-control" />
                                                        <div className="plan-item-card">
                                                            <div className="plan-item-head">
                                                                <div className="plan-item-heading">
                                                                    <h4 className="plan-item-title card-title title">Rookie</h4>
                                                                    <p className="sub-text">Enjoy entry level of invest &amp; earn money.</p>
                                                                </div>
                                                                <div className="plan-item-summary card-text">
                                                                    <div className="row">
                                                                        {/*<div class="col-6"><span class="lead-text">15%</span><span class="sub-text">Daily Interest</span></div>*/}
                                                                        {/* <div className="col-6"><span className="lead-text">20</span><span className="sub-text">days<br /><small>(duration)</small></span></div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="plan-item-body">
                                                                <div className="plan-item-desc card-text">
                                                                    <ul className="plan-item-desc-list">
                                                                        <li><span className="desc-label">Min Deposit</span> - <span className="desc-data">$100</span></li>
                                                                        <li><span className="desc-label">Max Deposit</span> - <span className="desc-data">$4,999</span></li>
                                                                        <li><span className="desc-label">Deposit Return</span> - <span className="desc-data">10% Daily for 1 day</span></li>
                                                                        {/*<li><span class="desc-label">Total Return</span> - <span class="desc-data">210%</span></li>*/}
                                                                    </ul>
                                                                    <div className="plan-item-action" onclick="planSelected('plan_1')"><label htmlFor="plan-iv-1" className="plan-label"><span onClick={() => { setinvestment(1) }} className="plan-label-base">Choose this plan</span><span className="plan-label-selected">Plan Selected</span></label></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li className="plan-item" id="plan_6">
                                                        <input type="radio" id="plan-iv-6" name="plan-iv" className="plan-control" />
                                                        <div className="plan-item-card">
                                                            <div className="plan-item-head">
                                                                <div className="plan-item-heading">
                                                                    <h4 className="plan-item-title card-title title">Rookie 2</h4>
                                                                    <p className="sub-text">Enjoy entry level of invest &amp; earn money.</p>
                                                                </div>
                                                                <div className="plan-item-summary card-text">
                                                                    <div className="row">
                                                                        {/*<div class="col-6"><span class="lead-text">15%</span><span class="sub-text">Daily Interest</span></div>*/}
                                                                        {/* <div className="col-6"><span className="lead-text">20</span><span className="sub-text">days<br /><small>(duration)</small></span></div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="plan-item-body">
                                                                <div className="plan-item-desc card-text">
                                                                    <ul className="plan-item-desc-list">
                                                                        <li><span className="desc-label">Min Deposit</span> - <span className="desc-data">$500</span></li>
                                                                        <li><span className="desc-label">Max Deposit</span> - <span className="desc-data">$4,999</span></li>
                                                                        <li><span className="desc-label">Deposit Return</span> - <span className="desc-data">20% Daily for 2 day</span></li>
                                                                        {/*<li><span class="desc-label">Total Return</span> - <span class="desc-data">210%</span></li>*/}
                                                                    </ul>
                                                                    <div className="plan-item-action" onclick="planSelected('plan_6')"><label htmlFor="plan-iv-6" className="plan-label"><span onClick={() => { setinvestment(6) }} className="plan-label-base">Choose this plan</span><span className="plan-label-selected">Plan Selected</span></label></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li className="plan-item" id="plan_2">
                                                        <input type="radio" id="plan-iv-2" name="plan-iv" className="plan-control" />
                                                        <div className="plan-item-card">
                                                            <div className="plan-item-head">
                                                                <div className="plan-item-heading">
                                                                    <h4 className="plan-item-title card-title title">Professional</h4>
                                                                    <p className="sub-text">Best plan for user to invest &amp; earn money.</p>
                                                                </div>
                                                                <div className="plan-item-summary card-text">
                                                                    <div className="row">
                                                                        {/*<div class="col-6"><span class="lead-text">45%</span><span class="sub-text">Weekly Interest</span></div>*/}
                                                                        {/* <div className="col-6"><span className="lead-text">3</span><span className="sub-text">months<br /><small>(duration)</small></span></div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="plan-item-body">
                                                                <div className="plan-item-desc card-text">
                                                                    <ul className="plan-item-desc-list">
                                                                        <li><span className="desc-label">Min Deposit</span> - <span className="desc-data">$2,000</span></li>
                                                                        <li><span className="desc-label">Max Deposit</span> - <span className="desc-data">$9,999</span></li>
                                                                        <li><span className="desc-label">Deposit Return</span> - <span className="desc-data">40% </span></li>
                                                                        {/*<li><span class="desc-label">Total Return</span> - <span class="desc-data">540%</span></li>*/}
                                                                    </ul>
                                                                    <div className="plan-item-action" onclick="planSelected('plan_2')"><label htmlFor="plan-iv-2" className="plan-label"><span onClick={() => { setinvestment(2) }} className="plan-label-base">Choose this plan</span><span className="plan-label-selected">Plan Selected</span></label></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="plan-item" id="plan_3">
                                                        <input type="radio" id="plan-iv-3" name="plan-iv" className="plan-control" />
                                                        <div className="plan-item-card">
                                                            <div className="plan-item-head">
                                                                <div className="plan-item-heading">
                                                                    <h4 className="plan-item-title card-title title">Megagoldtrades</h4>
                                                                    <p className="sub-text">Advance level of invest &amp; earn to quickly.</p>
                                                                </div>
                                                                <div className="plan-item-summary card-text">
                                                                    <div className="row">
                                                                        {/*<div class="col-6"><span class="lead-text">50%</span><span class="sub-text">Weekly Interest</span></div>*/}
                                                                        {/* <div className="col-6"><span className="lead-text">3</span><span className="sub-text">months<br /><small>(duration)</small></span></div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="plan-item-body">
                                                                <div className="plan-item-desc card-text">
                                                                    <ul className="plan-item-desc-list">
                                                                        <li><span className="desc-label">Min Deposit</span> - <span className="desc-data">$5,000</span></li>
                                                                        <li><span className="desc-label">Max Deposit</span> - <span className="desc-data">$19,999</span></li>
                                                                        <li><span className="desc-label">Deposit Return</span> - <span className="desc-data">60% Daily for 4 days</span></li>
                                                                        {/*<li><span class="desc-label">Total Return</span> - <span class="desc-data">600%</span></li>*/}
                                                                    </ul>
                                                                    <div className="plan-item-action" onclick="planSelected('plan_3')"><label htmlFor="plan-iv-3" className="plan-label"><span onClick={() => { setinvestment(3) }} className="plan-label-base">Choose this plan</span><span className="plan-label-selected">Plan Selected</span></label></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li className="plan-item" id="plan_4">
                                                        <input type="radio" id="plan-iv-4" name="plan-iv" className="plan-control" />
                                                        <div className="plan-item-card">
                                                            <div className="plan-item-head">
                                                                <div className="plan-item-heading">
                                                                    <h4 className="plan-item-title card-title title">Stocks</h4>
                                                                    <p className="sub-text">Advance level of invest in stocks&amp; earn to quickly.</p>
                                                                </div>
                                                                <div className="plan-item-summary card-text">
                                                                    <div className="row">
                                                                        {/*<div class="col-6"><span class="lead-text">50%</span><span class="sub-text">Weekly Interest</span></div>*/}
                                                                        {/* <div className="col-6"><span className="lead-text">3</span><span className="sub-text">months<br /><small>(duration)</small></span></div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="plan-item-body">
                                                                <div className="plan-item-desc card-text">
                                                                    <ul className="plan-item-desc-list">
                                                                        <li><span className="desc-label">Min Deposit</span> - <span className="desc-data">$10,000</span></li>
                                                                        <li><span className="desc-label">Max Deposit</span> - <span className="desc-data">$50,000</span></li>
                                                                        <li><span className="desc-label">Deposit Return</span> - <span className="desc-data">60% Daily for 5 days</span></li>
                                                                        {/*<li><span class="desc-label">Total Return</span> - <span class="desc-data">600%</span></li>*/}
                                                                    </ul>
                                                                    <div className="plan-item-action" onclick="planSelected('plan_4')"><label htmlFor="plan-iv-4" className="plan-label"><span onClick={() => { setinvestment(4) }} className="plan-label-base">Choose this plan</span><span className="plan-label-selected">Plan Selected</span></label></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li className="plan-item" id="plan_5">
                                                        <input type="radio" id="plan-iv-5" name="plan-iv" className="plan-control" />
                                                        <div className="plan-item-card">
                                                            <div className="plan-item-head">
                                                                <div className="plan-item-heading">
                                                                    <h5 className="plan-item-title card-title title">Commodities</h5>
                                                                    <p className="sub-text">Advance level of invest in commodities &amp; earn to quickly.</p>
                                                                </div>
                                                                <div className="plan-item-summary card-text">
                                                                    <div className="row">
                                                                        {/*<div class="col-6"><span class="lead-text">50%</span><span class="sub-text">Weekly Interest</span></div>*/}
                                                                        {/* <div className="col-6"><span className="lead-text">3</span><span className="sub-text">months<br /><small>(duration)</small></span></div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="plan-item-body">
                                                                <div className="plan-item-desc card-text">
                                                                    <ul className="plan-item-desc-list">
                                                                        <li><span className="desc-label">Min Deposit</span> - <span className="desc-data">$5,000</span></li>
                                                                        <li><span className="desc-label">Max Deposit</span> - <span className="desc-data">Unlimited</span></li>
                                                                        <li><span className="desc-label">Deposit Return</span> - <span className="desc-data">60% Daily for 6days</span></li>
                                                                        {/*<li><span class="desc-label">Total Return</span> - <span class="desc-data">600%</span></li>*/}
                                                                    </ul>
                                                                    <div className="plan-item-action" onclick="planSelected('plan_5')"><label htmlFor="plan-iv-5" className="plan-label"><span onClick={() => { setinvestment(5) }} className="plan-label-base">Choose this plan</span><span className="plan-label-selected">Plan Selected</span></label></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>




                                                </ul>
                                            </div>
                                            <div className="plan-iv-actions text-center"><button className="btn btn-primary btn-lg" type="button" id="plan_button_selected" > <span onClick={proceedToInvestment}>Proceed to Invest</span> <em className="icon ni ni-arrow-right" /></button></div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nk-footer nk-footer-fluid bg-lighter">
                        <div className="container-xl wide-lg">
                            <div className="nk-footer-wrap">
                                <div className="nk-footer-copyright"> © 2020  <a href="#">Megagoldtrades Investment Management Company</a></div>
                                <div className="nk-footer-links">
                                    <ul className="nav nav-sm">
                                        {/* <li class="nav-item"><a class="nav-link" href="#">Terms</a></li>
                           <li class="nav-item"><a class="nav-link" href="#">Privacy</a></li>
                           <li class="nav-item"><a class="nav-link" href="#">Help</a></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="nk-sticky-toolbar">
                <li className="demo-settings"><a className="toggle tipinfo" data-target="settingPanel" href="#" title="Demo Settings"><em className="icon ni ni-setting-alt" /></a></li>
            </ul>
            <div className="nk-demo-panel toggle-slide toggle-slide-right" data-content="settingPanel" data-toggle-overlay="true" data-toggle-body="true" data-toggle-screen="any">
                <div className="nk-demo-head">
                    <h6 className="mb-0">Preview Settings</h6>
                    <a className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2" data-target="settingPanel" href="#"><em className="icon ni ni-cross" /></a>
                </div>
                <div className="nk-opt-panel" data-simplebar>
                    <div className="nk-opt-set">
                        <div className="nk-opt-set-title">Main UI Style</div>
                        <div className="nk-opt-list col-2x">
                            <div className="nk-opt-item only-text active" data-key="style" data-update="ui-default"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Default</span></span></div>
                            <div className="nk-opt-item only-text" data-key="style" data-update="ui-clean"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Clean</span></span></div>
                            <div className="nk-opt-item only-text" data-key="style" data-update="ui-shady"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Shady</span></span></div>
                            <div className="nk-opt-item only-text" data-key="style" data-update="ui-softy"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Softy</span></span></div>
                        </div>
                    </div>
                    <div className="nk-opt-set nk-opt-set-header">
                        <div className="nk-opt-set-title">Header Style</div>
                        <div className="nk-opt-list col-4x">
                            <div className="nk-opt-item active" data-key="header" data-update="is-light"><span className="nk-opt-item-bg is-light"><span className="bg-lighter" /></span><span className="nk-opt-item-name">White</span></div>
                            <div className="nk-opt-item" data-key="header" data-update="is-default"><span className="nk-opt-item-bg is-light"><span className="bg-light" /></span><span className="nk-opt-item-name">Light</span></div>
                            <div className="nk-opt-item" data-key="header" data-update="is-dark"><span className="nk-opt-item-bg"><span className="bg-dark" /></span><span className="nk-opt-item-name">Dark</span></div>
                            <div className="nk-opt-item" data-key="header" data-update="is-theme"><span className="nk-opt-item-bg"><span className="bg-theme" /></span><span className="nk-opt-item-name">Theme</span></div>
                        </div>
                        <div className="nk-opt-set-title">Header Option</div>
                        <div className="nk-opt-list col-2x">
                            <div className="nk-opt-item only-text active" data-key="header_opt" data-update="is-regular"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Regular</span></span></div>
                            <div className="nk-opt-item only-text" data-key="header_opt" data-update="nk-header-fixed"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Fixed</span></span></div>
                        </div>
                    </div>
                    <div className="nk-opt-set nk-opt-set-skin">
                        <div className="nk-opt-set-title">Primary Skin</div>
                        <div className="nk-opt-list">
                            <div className="nk-opt-item active" data-key="skin" data-update="default"><span className="nk-opt-item-bg"><span className="skin-default" /></span><span className="nk-opt-item-name">Default</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="blue"><span className="nk-opt-item-bg"><span className="skin-blue" /></span><span className="nk-opt-item-name">Blue</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="egyptian"><span className="nk-opt-item-bg"><span className="skin-egyptian" /></span><span className="nk-opt-item-name">Egyptian</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="purple"><span className="nk-opt-item-bg"><span className="skin-purple" /></span><span className="nk-opt-item-name">Purple</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="green"><span className="nk-opt-item-bg"><span className="skin-green" /></span><span className="nk-opt-item-name">Green</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="red"><span className="nk-opt-item-bg"><span className="skin-red" /></span><span className="nk-opt-item-name">Red</span></div>
                        </div>
                    </div>
                    <div className="nk-opt-set">
                        <div className="nk-opt-set-title">Skin Mode</div>
                        <div className="nk-opt-list col-2x">
                            <div className="nk-opt-item active" data-key="mode" data-update="theme-light"><span className="nk-opt-item-bg is-light"><span className="theme-light" /></span><span className="nk-opt-item-name">Light Skin</span></div>
                            <div className="nk-opt-item disabled" data-key="mode" data-update="theme-dark"><span className="nk-opt-item-bg"><span className="theme-dark" /></span><span className="nk-opt-item-name">Dark Skin <small>(Soon)</small></span></div>
                        </div>
                    </div>
                    <div className="nk-opt-reset"><a href="#" className="reset-opt-setting">Reset Setting</a></div>
                </div>
            </div>
        </div>
    )
}

export default Investmentplans